import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { PageHeader, Button, Form, Card, Input, Select, Spin, notification } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import { FormConfig } from "../../../../shared/config/GlobalConfig";
import UsuariosApi from "../../api/UsuariosApi";
import PresentacionesApi from "../../../presentaciones/api/PresentacionesApi";
import TipoUsuarioSelectFormItem from "../../../../shared/components/tipoUsuarioSelectFormItem";
import DniFormItem from "../../../../shared/components/dniFormItem";
import PresentacionSelectFormItem from "../../../../shared/components/presentacionSelectFormItem";
import EstablecimientoDeclaradoSelectForm from "../../../../shared/components/establecimientoDeclaradoSelectForm";
import AuthenticationHelper from "../../../../shared/helpers/authenticationHelper";
import Usuario_EstablecimientoApi from "../../../usuario_establecimiento/api/Usuario_EstablecimientoApi";
import AuthApi from "../../../auth/api/AuthApi";
import CampoObligatorio from "../../../../shared/components/campoObligatorio";
import ClaveFormItem from "../../../../shared/components/claveFormItem";
import EstablecimientosApi from "../../../establecimientos/api/EstablecimientosApi";

const FormItem = Form.Item;
const { Option } = Select;
let idPresentacionAnterior;
let idTipoUsuarioAnterior;
let establecimientosPresentacion;
let presentacionSelecJSON;

const UsuarioForm = (props) => {
  const history = useHistory();
  const [tipoUsuarios, setTipoUsuarios] = useState([]);
  const [tipoUsuarioOperador, setTipoUsuarioOperador] = useState([]);
  const [presentaciones, setPresentaciones] = useState([]);
  const [working, setWorking] = useState(false);
  const [form] = Form.useForm();
  const [establecimientosPresentacionSeleccionada, setEstablecimientosPresentacionSeleccionada] = useState([]);
  const [establecimientosEmpresa, setEstablecimientosEmpresa] = useState([]);
  const [usuarioAdministrador, setUsuarioAdministrador] = useState(true);
  const [usuarioOperador, setUsuarioOperador] = useState();
  const [presentacionPrivada, setPresentacionPrivada] = useState();
  const [idUltimoUsuario, setIdUltimoUsuario] = useState();
  let usuarioSesion = AuthenticationHelper.getTipoUsuario();
  let usuarioEdit = props.location.usuario;


  useEffect(() => {
    loadTablas();
    limpiarSesionStorage();
  }, []);

  const loadTablas = () => {
    idPresentacionAnterior = null;
    idTipoUsuarioAnterior = null;

    UsuariosApi.GetTipoUsuarios().then(response => { setTipoUsuarios(response); });
    UsuariosApi.GetTipoUsuarios().then(response => { setTipoUsuarioOperador(response[2]); });
    PresentacionesApi.GetAll().then(response => { setPresentaciones(response); });
    UsuariosApi.GetUltimoIdUsuario().then((response) => { setIdUltimoUsuario(response); });

    if (props.location.usuario !== undefined) {
      if (usuarioEdit.Id_Presentacion !== null) {
        setUsuarioAdministrador(false);
        PresentacionesApi.GetPresentacion(usuarioEdit.Id_Presentacion).then(response1 => {
          AuthenticationHelper.storePresentacionSeleccionada(response1);
          PresentacionesApi.GetTipoPresentacion(usuarioEdit.Id_Presentacion).then(response2 => {
            AuthenticationHelper.storeIdTipoPresentacion(response2);
            if (response2 == 1) {
              setPresentacionPrivada(false);
              if (usuarioEdit.Id_TipoUsuario == 3) {
                setUsuarioOperador(true);
                obtenerDatosPresentacion(response2, response1);
              }
            }
          });
        });
      }
      AuthenticationHelper.storeIdTipoUsuario(usuarioEdit.Id_TipoUsuario);
    }
  }

  const salir = () => {
    history.goBack();
    limpiarSesionStorage();
  }

  const limpiarSesionStorage = () => {
    AuthenticationHelper.removePresentacionSeleccionada();
    AuthenticationHelper.removeIdTipoPresentacion();
    AuthenticationHelper.removeIdTipoUsuario();
  }

  const cargarDatos = () => {
    let idTipoUsuario = AuthenticationHelper.getIdTipoUsuario(); //Es el usuario seleccionado
    let idTipoPresentacion = AuthenticationHelper.getIdTipoPresentacion(); //Id Presentacion seleccionada
    let presentacionSelec = AuthenticationHelper.getPresentacionSeleccionada();  //Presentacion seleccionada
    presentacionSelecJSON = JSON.parse(presentacionSelec);
    let idPresentacionSelec;
    if (presentacionSelecJSON != null) { idPresentacionSelec = presentacionSelecJSON.Id; }

    if (idTipoUsuarioAnterior != idTipoUsuario && idTipoUsuario !== null) {
      obtenerTipoUsuario(idTipoUsuario);
      idTipoUsuarioAnterior = idTipoUsuario;
    }
    if (idTipoUsuario == 3 && idTipoPresentacion != null) {
      if (idPresentacionAnterior != idPresentacionSelec) {
        obtenerDatosPresentacion(idTipoPresentacion, presentacionSelecJSON);
        idPresentacionAnterior = idPresentacionSelec;
      }
    }
  }

  const obtenerTipoUsuario = (idTUsuario) => {
    //idTUsuario == 1 (Administrador)
    if (idTUsuario == 1) {
      setUsuarioAdministrador(true);
    } else {
      setUsuarioAdministrador(false);
    }
    if (idTUsuario == 3) {
      setUsuarioOperador(true);
    } else {
      setUsuarioOperador(false)
    }
  }

  const obtenerDatosPresentacion = (idTPresentacion, presentacionS) => {
    //idTPresentacion == 2 (Privada)
    if (idTPresentacion == 2) {
      setPresentacionPrivada(true);
    } else {
      setPresentacionPrivada(false);
      setEstablecimientosPresentacionSeleccionada([]);
      if (presentacionS !== null) {
        setWorking(true);
        PresentacionesApi.Get(presentacionS.Id, presentacionS.Cuit)
          .then(response => {
            establecimientosPresentacion = response.AnexoI.EstablecimientosDeclarados;
            setEstablecimientosPresentacionSeleccionada(establecimientosPresentacion);
            setWorking(false);
          })
          .catch(error => {
            setWorking(false);
            notification.error({ message: 'Error', description: '¡No se pudo cargar los establecimientos de la presentacion!', placement: 'bottomRight' });
            salir();
          });
          EstablecimientosApi.GetEmpresasByCuit(presentacionS.Cuit).then((response) => { setEstablecimientosEmpresa(response);});
      }
    }
  }

  const onFinish = values => {
    var establecimientosValidos = [];
    if (values.Clave == values.ConfirmacionClave) {
      AuthApi.Encrypt(values.Clave)
        .then(responsePSW => {
          values.Clave = responsePSW.password;
          setWorking(true);
          let data = {
            ...values,
          }

          if (values.Id_TipoUsuario !== 1) {
            PresentacionesApi.Get(presentacionSelecJSON.Id, presentacionSelecJSON.Cuit)
              .then((response) => {
                if (values.Establecimientos !== undefined) {
                  values.Establecimientos.forEach(element => {
                    response.AnexoI.EstablecimientosDeclarados.forEach(element2 => {
                      if (element2.IdEstablecimientoEmpresa == element) { establecimientosValidos.push(element); }
                    });
                  });
                }

                if (props.location.usuario == undefined) {
                  UsuariosApi.Insert(data)
                    .then((response) => {
                      if (response !== 0) {
                        if (values.Establecimientos !== undefined) {
                          Usuario_EstablecimientoApi.Insert((idUltimoUsuario + 1), establecimientosValidos)
                            .then((response) => {
                              setWorking(false);
                              notification.success({ message: 'Exito', description: 'Establecimientos Asociados', placement: 'bottomRight' });
                            })
                            .catch((error) => { setWorking(false); });
                        } else {
                          setWorking(false);
                        }
                        notification.success({ message: 'Exito', description: 'Usuario Creado', placement: 'bottomRight' });
                        setWorking(false);
                        limpiarSesionStorage();
                        history.goBack();
                      } else {
                        notification.error({ message: 'Error', description: '¡El DNI o Correo ingresado ya existe!', placement: 'bottomRight' });
                        setWorking(false);
                        history.goBack();
                      }
                    })
                    .catch((error) => {
                      notification.error({ message: 'Error', description: '¡Las claves deben ser iguales!', placement: 'bottomRight' });
                      setWorking(false);
                    });

                } else {
                  UsuariosApi.Update(data)
                    .then((response) => {
                      if (response !== 0) {
                        Usuario_EstablecimientoApi.Delete(values.Id)
                          .then((response) => {
                            if (values.Establecimientos !== undefined) {
                              Usuario_EstablecimientoApi.Insert(values.Id, establecimientosValidos)
                                .then((response) => {
                                  setWorking(false);
                                })
                                .catch((error) => { setWorking(false); });
                            } else {
                              setWorking(false);
                            }
                          })
                          .catch((error) => { setWorking(false); });
                        notification.success({ message: 'Exito', description: 'Usuario Modificado', placement: 'bottomRight' });
                        setWorking(false);
                        limpiarSesionStorage();
                        history.goBack();
                      } else {
                        notification.error({ message: 'Error', description: '¡El DNI o Correo ingresado ya existe!', placement: 'bottomRight' });
                        setWorking(false);
                      }
                    })
                    .catch((error) => { setWorking(false); });
                }
              });

          } else {
            if (props.location.usuario == undefined) {
              UsuariosApi.Insert(data)
                .then((response) => {
                  if (response !== 0) {
                    notification.success({ message: 'Exito', description: 'Usuario Creado', placement: 'bottomRight' });
                    setWorking(false);
                    limpiarSesionStorage();
                    history.goBack();
                  } else {
                    notification.error({ message: 'Error', description: '¡El DNI o Correo ingresado ya existe!', placement: 'bottomRight' });
                    setWorking(false);
                  }
                })
                .catch((error) => { setWorking(false); });

            } else {
              UsuariosApi.Update(data)
                .then((response) => {
                  if (response !== 0) {
                    Usuario_EstablecimientoApi.Delete(values.Id)
                      .then((response) => {
                      })
                      .catch((error) => { setWorking(false); });
                    notification.success({ message: 'Exito', description: 'Usuario Modificado', placement: 'bottomRight' });
                    setWorking(false);
                    limpiarSesionStorage();
                    history.goBack();
                  } else {
                    notification.error({ message: 'Error', description: '¡El DNI o Correo ingresado ya existe!', placement: 'bottomRight' });
                    setWorking(false);
                  }
                })
                .catch((error) => { setWorking(false); });
            }
          }
        })
        .catch((error) => { setWorking(false); });
    } else {
      notification.error({ message: 'Error', description: '¡Las claves deben ser iguales!', placement: 'bottomRight' });
    }
  }

  return (
    <div style={{ backgroundColor: '#fff' }}>
      <PageHeader
        className="site-page-header"
        title={`${'Usuario'}`}
        //subTitle="Formulario"
        onBack={() => salir()}
      />
      <div style={{ padding: 20 }}>
        <Spin spinning={working}>
          <Card>
            <Form
              {...FormConfig.DefaultLayout}
              form={form}
              name="formulario"
              onFinish={onFinish}
              initialValues={props.location.usuario ? { ...usuarioEdit, Clave: props.location.claveUsuario, ConfirmacionClave: props.location.claveUsuario, Establecimientos: props.location.establecimientos } : undefined}
              scrollToFirstError
              onClick={cargarDatos}
            >

              {props.location.usuario?.Id ? <FormItem name="Id" label="Usuario" rules={FormConfig.DefaultRules}>
                <Input disabled />
              </FormItem> : ''}

              <FormItem name="Apellido" label="Apellido" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <FormItem name="Nombre" label="Nombre" rules={FormConfig.DefaultRules}>
                <Input />
              </FormItem>

              <DniFormItem name="Dni" label="DNI" rules={[...FormConfig.DefaultRules]} />

              <FormItem name="Correo" label="Correo" rules={[
                { required: true, message: "Por favor ingrese un valor" },
                { type: 'email', message: 'Por favor ingrese un email válido' }
              ]}>
                <Input />
              </FormItem>

              <ClaveFormItem
                name="Clave"
                label="Clave"
                rules={FormConfig.DefaultRules}
                showEyeIcon={usuarioEdit ? false : true} // Show eye icon if usuarioEdit exists
              />

              <FormItem name="ConfirmacionClave" label="Confirmar Clave" rules={FormConfig.DefaultRules}>
                <Input type="password" />
              </FormItem>

              {
                usuarioSesion == "Administrador Presentacion" ?
                  <>
                    <TipoUsuarioSelectFormItem name="Id_TipoUsuario" label="Tipo de Usuario"
                      tabla={[tipoUsuarioOperador]} rules={FormConfig.DefaultRules} //Solo tipo operador
                    />
                  </>
                  : <>
                    <TipoUsuarioSelectFormItem name="Id_TipoUsuario" label="Tipo de Usuario"
                      tabla={tipoUsuarios} rules={FormConfig.DefaultRules}
                    />
                  </>
              }

              {
                !usuarioAdministrador ?
                  <>
                    {
                      usuarioSesion == "Administrador Presentacion" ?
                        <>
                          <PresentacionSelectFormItem name="Id_Presentacion" label="Presentacion"
                            tabla={JSON.parse(AuthenticationHelper.getPresentacionUsuario())} rules={FormConfig.DefaultRules} //Solo la presentacion asociada con el usuario
                          />
                        </>
                        : <>
                          <PresentacionSelectFormItem name="Id_Presentacion" label="Presentacion"
                            tabla={presentaciones} rules={FormConfig.DefaultRules} //Todas las presentaciones
                          />
                        </>
                    }
                    {
                      usuarioOperador ?
                        <>
                          {
                            !presentacionPrivada ?
                              <>
                                <EstablecimientoDeclaradoSelectForm name="Establecimientos" label="Establecimientos"
                                  tabla={establecimientosPresentacionSeleccionada} establecimientos={establecimientosEmpresa}
                                  rules={FormConfig.DefaultRules}
                                />
                              </>
                              : null
                          }
                        </>
                        : null
                    }
                  </> :
                  null
              }

              <CampoObligatorio/>

              <FormItem wrapperCol={{ span: 16, offset: 8 }}>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>Guardar</Button>
              </FormItem>

            </Form>
          </Card>
        </Spin>
      </div>
    </div>
  );
}

export default UsuarioForm;