import React from "react";
import { Form, Input } from "antd";

const FormItem = Form.Item;

const ClaveFormItem = (props) => {
  const { showEyeIcon, ...restProps } = props; // Extraemos showEyeIcon y las demás props

  return (
    <FormItem
      {...restProps}
      rules={[
        ...props.rules,
        {
          validator(_, value) {
            const reg = /^(?=.*\d)(?=.*[A-Z])\w{8,}$/;
            return value.length > 0 && reg.test(value)
              ? Promise.resolve()
              : Promise.reject(
                  new Error(
                    "La clave debe contener como mínimo 8 caracteres, ser alfanumérico y contener al menos una mayúscula"
                  )
                );
          },
        },
      ]}
    >
      <Input.Password
        minLength={8}
        style={{ width: "100%" }}
        visibilityToggle={showEyeIcon} // Aquí controlamos si el ojo debe aparecer
      />
    </FormItem>
  );
};

export default ClaveFormItem;