import Request from "../../../shared/helpers/request";

const NominasApi = {
  Save: data => {
    return Request
      .post('nominas', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  Edit: data => {
    return Request
      .put('nominas', data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  Delete: data => {
    return Request
      .delete('nominas', data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },
  ImportCSV: file => {
    return Request.upload('nominas/importar-csv', file)
      .then(response => {
        return response;
      })
      .catch(error => {
        throw error; 
      });
  },
}

export default NominasApi;